<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" :before-close="handleClose">
    <div class="edit-box" v-loading="loading">
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          年级
        </div>
        <el-select v-model="form.grade_id" placeholder="请选择年级"  style="width: 75%;">
          <el-option v-for="(item,index)  in gradeList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          班级
        </div>
        <el-select v-model="form.class_id" placeholder="请选择班级" filterable style="width: 75%;">
          <el-option v-for="(item,index) in showClassList" :key="index" :label="item.class_name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          导师姓名
        </div>
        <el-select
            v-model="form.user_id"
            placeholder="输入姓名搜索"
            filterable
            remote
            :remote-method="handleSearchTeacher"
            style="width: 75%;"
        >
          <el-option v-for="(item,index)  in tutorList" :key="index" :label="item.username" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="u-f-item input-box">
        <div class="input-lebal" style="text-align: right;width: 80px;">
          <span>*</span>
          学生姓名
        </div>
        <el-select
            v-model="form.student_user_id"
            placeholder="输入姓名搜索"
            style="width: 75%;"
            filterable
            remote
            :remote-method="handleSearchStudent"
        >
          <el-option v-for="(item,index)  in studentList" :key="index" :label="item.realname" :value="item.id"></el-option>
        </el-select>
      </div>
    </div>
    <div slot="footer" class="dialog-footer u-f-item u-f-jsb">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" size="small" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'TutorSetForm',
  props: {
    gradeList: Array,
    classList: Array
  },
  computed: {
    title () {
      return this.form.id ? '编辑人员' : '添加'
    },
    showClassList () {
      if (!this.form.grade_id) {
        return []
      }
      return this.classList.filter(item => item.grade_id === this.form.grade_id)
    }
  },
  data () {
    return {
      visible: false,
      loading: false,
      form: {},
      tutorList: [],
      studentList: []
    }
  },
  methods: {
    open (record) {
      this.form = record || {}
      this.visible = true
      if (this.form.id) {
        this.loading = true
        Promise.all([
          this.handleSearchTeacher(this.form.user_name),
          this.handleSearchStudent(this.form.student_user_name)
        ])
            .finally(() => {
              this.loading = false
            })
      }
    },

    // 关闭
    handleClose () {
      this.form = {}
      this.visible = false
      this.tutorList = []
      this.studentList = []
    },

    // 教师搜索
    handleSearchTeacher (name) {
      return this.$api.teachers.teachers({ search: name, limit: 15 })
          .then(result => {
            if (result.data.code === 1) {
              this.tutorList = result.data.data.rows
            } else {
              this.$message.error(result.data.msg)
            }
          })
    },

    // 学生搜索
    handleSearchStudent (name) {
      return this.$api.setting.classStudent({ name }).then(res=>{
        if(res.data.code===1){
          this.studentList = res.data.data.data;
        }
      })
    },

    // 提交
    handleSubmit () {
      this.request(this.form).then(res=>{
        if(res.data.code===1){
          this.$message.success(this.form.id ? '修改成功' : '添加成功');
          this.handleClose()
          this.$emit('success')
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },

    request (data) {
      if (data.id) {
        return this.$api.setting.modifyedit(data)
      }
      return this.$api.setting.modifyAdd(data)
    }
  }
}
</script>

<style scoped>

</style>
